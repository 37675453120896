export const SIGN_UP = "SIGN_UP";
export const SIGN_IN = "SIGN_IN";
export const SUBMITTING = "SUBMITTING";
export const CREATE_ACCOUNT_SUCCESS = "CREATE_ACCOUNT_SUCCESS";
export const CREATE_ACCOUNT_ERROR = "CREATE_ACCOUNT_ERROR";
export const TOAST = "TOAST";
export const ADD_TO_STACKED_TOASTS = "ADD_TO_STACKED_TOASTS";
export const REMOVE_FROM_STACKED_TOASTS = "REMOVE_FROM_STACKED_TOASTS";
export const REDIRECT = "REDIRECT";
export const AUTH = "AUTH";
export const APP_VALUES = "APP_VALUES";
export const RECIPIENTS = "RECIPIENTS";
export const RECIPIENT = "RECIPIENT";
export const NEW_RECIPIENT = "NEW_RECIPIENT";
export const LOADING = "LOADING";
export const TRANSFER = "TRANSFER";
export const RESET_TRANSFER = "RESET_TRANSFER";
export const TRANSFER_QUOTE = "TRANSFER_QUOTE";
export const NOTIFICATIONS = "NOTIFICATIONS";
export const EXCHANGE_SPREADS = "EXCHANGES_SPREADS"
export const EXCHANGES = "EXCHANGES"
export const CONFIRM = "CONFIRM";
export const TRANSACTIONS = "TRANSACTIONS";
export const ISMOBILE = "ISMOBILE";
