import {
  Input,
  InputProps,
  Select,
  Space,
  Tabs,
  TabsProps,
  Tooltip,
} from "antd";
import {
  getFlagURL,
  replaceUnderScore,
  transferMethodsInWords,
} from "components/pages/transcations-flow/utils/reuseableUtils";
import { userAppValues } from "components/pages/transcations-flow/utils/useAppValues";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { TRANSFER } from "redux/actionTypes";
import styled from "styled-components";
import { countriesTransferMethodAvailability } from "util/constants";
import {
  Methods,
  transferMethodsInNumbers,
} from "../../transfer-method/TransferMethod";
import { handleValueIsNaN } from "../GetQuoteHelper";
import { CalculatorInputStyles } from "../GetQuoteStyles";

const { Option } = Select;

interface NumberInputProps extends InputProps {
  value: string;
  isError: boolean;
  isPayin: boolean;
  errorMessage: string;
  prefix?: React.ReactNode;
  suffix?: React.ReactNode;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const NumberInput: React.FC<NumberInputProps> = ({
  value,
  onChange,
  isError,
  isPayin,
  errorMessage,
  prefix,
  suffix,
  ...props
}) => {
  const isMobile = useSelector((state: any) => state.isMobileView);
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (!isNaN(Number(value.replace(/,/g, "")))) {
      onChange({
        ...e,
        target: {
          ...e.target,
          // value: formatNumberWithCommas(value.replace(/,/g, "")),
          value: String(handleValueIsNaN(value.replace(/,/g, ""))),
        },
      });
    }
  };

  return (
    <CalculatorInputStyles $error={isError}>
      <Space direction="vertical" size={8}>
        <span className="label">{isPayin ? "You send" : "Recipient gets"}</span>
        <Input
          {...props}
          value={value}
          onChange={handleChange}
          size="large"
          maxLength={11}
          status={isError ? "error" : ""}
          prefix={prefix || <span />}
          suffix={isMobile ? <span /> : suffix || <span />}
        />
        {isError && <span className="error_message">{errorMessage}</span>}
      </Space>
    </CalculatorInputStyles>
  );
};

export default NumberInput;

export const SelectAfter = (
  defaultValue: string,
  payInCurrency: string,
  isPayin: boolean
) => {
  const transfer = useSelector((state: any) => state.transfer);
  const { transferMethod, payoutCurrency } = transfer || {};
  const dispatch = useDispatch();
  const { PayoutCountries, PayinCountries } = userAppValues();
  const PayInCountryData = PayinCountries.find(
    (country) => country.currency === payInCurrency
  );
  const getEquivalentCountryCode = (value: string) => {
    const countries = PayoutCountries || {};
    return countries.find((country: any) => country.currency === value)
      ?.countryCode;
  };

  //Update toSend countryCode state value.
  useEffect(() => {
    dispatch({
      type: TRANSFER,
      payload: {
        ...transfer,
        toSend: {
          ...transfer.toSend,
          countryCode: payInCurrency,
        },
      },
    });
  }, []);

  const handlePayOutCountryChange = (value: string) => {
    dispatch({
      type: TRANSFER,
      payload: {
        ...transfer,
        payoutCurrency: value,
        toReceive: {
          ...transfer.toReceive,
          countryCode: getEquivalentCountryCode(value),
        },
      },
    });
  };

  // Additional logic to handle selection of the next available option ---
  // -- if current sellection is disabled
  useEffect(() => {
    const isCurrentPayoutDisabled = PayoutCountries.some(
      (country) =>
        country.currency === payoutCurrency &&
        !countriesTransferMethodAvailability[country.countryCode]?.[
          transferMethod
        ]
    );

    if (isCurrentPayoutDisabled) {
      const nextAvailableOption = PayoutCountries.find(
        (country) =>
          countriesTransferMethodAvailability[country.countryCode]?.[
            transferMethod
          ]
      );

      if (nextAvailableOption) {
        handlePayOutCountryChange(nextAvailableOption.currency);
      }
    }
  }, [
    transferMethod,
    payoutCurrency,
    PayoutCountries,
    countriesTransferMethodAvailability,
    handlePayOutCountryChange,
  ]);

  return isPayin && PayInCountryData !== undefined ? (
    <Space align="center">
      <img
        src={getFlagURL(
          PayInCountryData.currency === "EUR"
            ? "EU"
            : PayInCountryData.countryCode
        )}
        alt={PayInCountryData.name}
        style={{
          width: "24px",
          height: "18px",
          marginTop: "5px",
        }}
      />
      <span>{PayInCountryData.currency}</span>
    </Space>
  ) : (
    <Select
      defaultValue={defaultValue}
      onChange={handlePayOutCountryChange}
      value={payoutCurrency}
    >
      {PayoutCountries.map((country, index) => {
        const isPayoutCountryNotSupported =
          !countriesTransferMethodAvailability[country.countryCode]?.[
            transferMethod
          ];

        const promptText = isPayoutCountryNotSupported
          ? `Sorry, ${replaceUnderScore(
              transferMethod
            )}s are not supported in ${country.currency}.`
          : "";

        return (
          <Option
            value={country.currency}
            key={country.name + index}
            disabled={isPayoutCountryNotSupported}
          >
            <Tooltip title={promptText}>
              <Space align="center">
                <img
                  src={getFlagURL(country.countryCode)}
                  alt={country.name}
                  style={{
                    width: "24px",
                    height: "18px",
                    marginTop: "5px",
                    opacity: isPayoutCountryNotSupported ? 0.3 : 1,
                  }}
                />
                <span>{country.currency}</span>
              </Space>
            </Tooltip>
          </Option>
        );
      })}
    </Select>
  );
};

export const TransferMethodsTabs = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const transfer = useSelector((state: any) => state.transfer);
  const isMobile = useSelector((state: any) => state.isMobileView);
  const method: string = transferMethodsInWords[transfer.transferMethod];

  const onTabChange = (key: string) => {
    //update url params
    const params = new URLSearchParams(location.search);
    const param = "method";
    params.set(param, transferMethodsInNumbers[key]);

    const newUrl = `${location.pathname}?${params.toString()}`;
    history.push(newUrl);

    //upadte global state
    dispatch({
      type: TRANSFER,
      payload: { ...transfer, transferMethod: key },
    });
  };

  const items: TabsProps["items"] = Methods.map((item) => ({
    label: item.name,
    key: item.key,
  }));

  return (
    <TransferMethodsTabsWrapper>
      <Tabs
        centered={isMobile ? false : true}
        className="tab"
        activeKey={method}
        items={items}
        onChange={onTabChange}
      />
    </TransferMethodsTabsWrapper>
  );
};

const TransferMethodsTabsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-grow: 1;
  overflow: hidden;

  .tab {
    width: 100%;
  }
`;
