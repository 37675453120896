import {
  InfoCircleOutlined,
  LoadingOutlined,
  SwapOutlined,
} from "@ant-design/icons";
import { Avatar, Divider, Space, Switch, Tooltip } from "antd";
import { transferMethodsInWords } from "components/pages/transcations-flow/utils/reuseableUtils";
import { Colors } from "components/pages/transcations-flow/utils/stylesVariables";
import { userAppValues } from "components/pages/transcations-flow/utils/useAppValues";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TRANSFER } from "redux/actionTypes";
import { getServiceRate } from "redux/actions/actions";
import {
  calculatePayAmount,
  checkAmountValidation,
  formatNumberWithCommas,
  getOperatorFeeDescription,
  getRewardsValues,
  handleValueIsNaN,
  removeLeadingZeros,
  updateTotalPayinAndPayoutValues,
  useExchangeRate,
} from "../GetQuoteHelper";
import {
  CalculatorTabStyles,
  ExchangeCalculatorStyles,
  PayoutInclusiveStyles,
  SummaryFlexItem,
  SummaryWrapper,
} from "../GetQuoteStyles";
import NumberInput, {
  SelectAfter,
  TransferMethodsTabs,
} from "./CalculatorInput";
import { PromoInput } from "./PromoInput";

export const ExchangeCalculator = () => {
  const user = useSelector((state: any) => state.auth.user);
  const dispatch = useDispatch();
  const transfer = useSelector((state: any) => state.transfer);
  const { PayinCountries } = userAppValues();
  const userCountryCode = user?.profile?.location_country;
  const [isPayinInputActive, setIsPayinInputActive] = useState(true);
  const [serviceRateData, setServiceRateData] = useState<any>({});
  const {
    payoutCurrency,
    payinCurrency,
    allowOperatorFee,
    payinActualValue,
    transferMethod,
    payoutActualValue,
    exchangeRate,
    promoType,
    freeOperatorFee,
    fixedRateOperatorFee,
  } = transfer;

  const onSwitchChange = (checked: boolean) => {
    dispatch({
      type: TRANSFER,
      payload: {
        ...transfer,
        allowOperatorFee: checked,
      },
    });
  };

  const userCountryInfo = PayinCountries.find(
    (country) =>
      country.countryCode?.toLowerCase() === userCountryCode?.toLowerCase()
  );

  const { data: rqExchangeRate, isLoading: isLoadingRate } = useExchangeRate(
    payinCurrency,
    payoutCurrency,
    userCountryInfo?.currency === payinCurrency
  );

  const isMobileMoneyMethod =
    transferMethodsInWords[transferMethod] === "mobile_money";

  // Dispatch an action to update the Redux state with the cached rqExchangeRate
  useEffect(() => {
    dispatch({
      type: TRANSFER,
      payload: {
        ...transfer,
        exchangeRate: handleValueIsNaN(rqExchangeRate?.rate),
        conversionRate: rqExchangeRate,
      },
    });
  }, [rqExchangeRate]);

  // Recalculate payout value based on the new exchange rate
  useEffect(() => {
    updatePayoutValue();
  }, [exchangeRate]);

  const updatePayoutValue = () => {
    dispatch({
      type: TRANSFER,
      payload: {
        ...transfer,
        payoutActualValue: Math.round(exchangeRate * payinActualValue).toFixed(
          2
        ),
      },
    });
  };

  // State to store the validation result
  const [calculatorInputValidator, setCalculatorInputValidator] = useState({
    errorMessage: "",
    isPayin: false,
    isError: false,
    fee: 0,
    operatorFeeCallout: "",
    transferLimitMaxOut: false,
  });

  useEffect(() => {
    // Get service rate details for the current transfer method
    const serviceRate = getServiceRate(transferMethod);
    setServiceRateData(serviceRate);
    // Function to get the validation state
    const validation = checkAmountValidation(serviceRate, isPayinInputActive);

    const serviceFee =
      promoType === "FIXED_RATE" ? fixedRateOperatorFee : validation.fee;

    updateTotalPayinAndPayoutValues(
      serviceFee,
      handleValueIsNaN(serviceRate.operatorFeeInOriginCurrency),
      isPayinInputActive,
      serviceRate.isInOriginCurrency
    );

    setCalculatorInputValidator((prev: any) => ({
      ...prev,
      errorMessage: validation.errorMessage,
      isPayin: validation.isPayin,
      isError: validation.isError,
      fee: serviceFee,
      transferLimitMaxOut: validation.transferLimitMaxOut,
    }));
  }, [
    transferMethod,
    payinCurrency,
    payoutCurrency,
    payinActualValue,
    payoutActualValue,
    allowOperatorFee,
    exchangeRate,
    promoType,
  ]);

  const payIn = String(payinActualValue);
  const payout = String(payoutActualValue);

  const handlePayInChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = removeLeadingZeros(e.target.value.replace(/,/g, ""));

    setIsPayinInputActive(true);

    dispatch({
      type: TRANSFER,
      payload: {
        ...transfer,
        payinActualValue: value,
        payoutActualValue: calculatePayAmount(value, exchangeRate, false),
      },
    });
  };

  const handlePayoutChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = removeLeadingZeros(e.target.value.replace(/,/g, ""));

    setIsPayinInputActive(false);

    dispatch({
      type: TRANSFER,
      payload: {
        ...transfer,
        payinActualValue: calculatePayAmount(value, exchangeRate, true),
        payoutActualValue: value,
      },
    });
  };

  return (
    <ExchangeCalculatorStyles>
      <CalculatorTabStyles>
        <TransferMethodsTabs />
        <>
          <NumberInput
            isPayin={true}
            disabled={isLoadingRate}
            value={formatNumberWithCommas(payIn)}
            onChange={handlePayInChange}
            placeholder="0.00"
            addonAfter={SelectAfter(payoutCurrency, payinCurrency, true)}
            isError={
              calculatorInputValidator.isError &&
              calculatorInputValidator.isPayin
            }
            errorMessage={calculatorInputValidator.errorMessage}
            // suffix={
            //   !isMobileMoneyMethod &&
            //   Math.abs(
            //     Number(
            //       calculatorInputValidator.operatorFeeCallout.replace(/,/g, "")
            //     )
            //   ) > 0 && (
            //     <OperatorFeeCallout>
            //       {calculatorInputValidator.operatorFeeCallout}
            //     </OperatorFeeCallout>
            //   )
            // }
          />

          <div className="rate_and_icon">
            <Space align="center" split>
              <span>{`1 ${payinCurrency} = ${formatNumberWithCommas(
                exchangeRate.toFixed(2)
              )} ${payoutCurrency}`}</span>
              <Avatar
                icon={
                  isLoadingRate ? (
                    <LoadingOutlined rev={undefined} />
                  ) : (
                    <SwapOutlined
                      rev={undefined}
                      style={{ rotate: "-90deg" }}
                    />
                  )
                }
                style={{ background: Colors.sbGreen }}
              />
            </Space>
          </div>

          <NumberInput
            isPayin={false}
            isError={
              calculatorInputValidator.isError &&
              !calculatorInputValidator.isPayin
            }
            disabled={isLoadingRate}
            value={formatNumberWithCommas(payout)}
            onChange={handlePayoutChange}
            placeholder="0.00"
            addonAfter={SelectAfter(payoutCurrency, payinCurrency, false)}
            errorMessage={calculatorInputValidator.errorMessage}
            // suffix={
            //   isMobileMoneyMethod &&
            //   Math.abs(
            //     Number(
            //       calculatorInputValidator.operatorFeeCallout.replace(/,/g, "")
            //     )
            //   ) > 0 && (
            //     <OperatorFeeCallout>
            //       {calculatorInputValidator.operatorFeeCallout}
            //     </OperatorFeeCallout>
            //   )
            // }
          />
        </>

        <Divider style={{ margin: 6 }} />

        <PromoInput />

        <>
          {/* <Divider style={{ margin: 0 }} /> */}
          <PayoutInclusiveStyles>
            <Space>
              <p>
                {`Partner Payout fee ${allowOperatorFee ? `` : `not`} included`}
              </p>

              <Tooltip title={getOperatorFeeDescription(transferMethod)}>
                <InfoCircleOutlined rev={undefined} />
              </Tooltip>
            </Space>

            <div className="payout_inclusive">
              <span>
                {`Operator fee  ${allowOperatorFee ? `included` : `excluded`}`}
              </span>
              <Switch
                checked={allowOperatorFee}
                onChange={onSwitchChange}
                disabled={
                  calculatorInputValidator.transferLimitMaxOut ||
                  (promoType === "FREE_OPERATOR_FEE" && freeOperatorFee)
                }
              />
            </div>
          </PayoutInclusiveStyles>
        </>

        {/* Qoute summary */}
        <QuoteSummary serviceRateData={serviceRateData} />
      </CalculatorTabStyles>
    </ExchangeCalculatorStyles>
  );
};

const QuoteSummary = ({ serviceRateData }: { serviceRateData: any }) => {
  const user = useSelector((state: any) => state.auth.user);
  const transfer = useSelector((state: any) => state.transfer);

  const {
    operatorFee: fee,
    operatorFeeInOriginCurrency: feeInOriginCurrency,
    isInOriginCurrency,
  } = serviceRateData || {};

  const {
    payoutCurrency,
    payinCurrency,
    allowOperatorFee,
    payinActualValue,
    totalToPay,
    promoDiscountValue,
    promoRate,
    promoType,
    promoFreeOperatorFee,
    totalToSend,
  } = transfer;

  const rewards = getRewardsValues(user);

  const totalToSendToNearestWhole = Math.round(totalToSend);
  const nonNegativeTotalToSend = Math.max(totalToSendToNearestWhole, 0);
  const operatorFee = handleValueIsNaN(fee);
  const operatorFeeInOriginCurrency = handleValueIsNaN(feeInOriginCurrency);

  const operatorFeeSign = (): string => {
    if (isInOriginCurrency) {
      return allowOperatorFee ? "+" : "-";
    } else {
      return "+";
    }
  };

  const formattedTotalToPay =
    totalToPay && (payinActualValue <= 0 || totalToPay < 0) //handle negative value with total to pay
      ? 0
      : formatNumberWithCommas(totalToPay?.toFixed(2));

  const ToatlPayoutAmount = () => {
    if ((!isInOriginCurrency && !allowOperatorFee) || promoFreeOperatorFee) {
      return `${payoutCurrency} ${formatNumberWithCommas(
        nonNegativeTotalToSend
      )}`;
    }
    const extraAmount = isInOriginCurrency ? 0 : operatorFee;

    return `${payoutCurrency} ${formatNumberWithCommas(
      Math.round(nonNegativeTotalToSend + extraAmount)
    )}`;
  };

  return (
    <SummaryWrapper>
      <SummaryFlexItem>
        <p>You send</p>
        <p>
          {payinCurrency} {formatNumberWithCommas(payinActualValue)}
        </p>
      </SummaryFlexItem>
      <SummaryFlexItem
        $isStrikethrough={
          (!isInOriginCurrency && !allowOperatorFee) || promoFreeOperatorFee
        }
      >
        <p>Operator fee</p>
        <p>
          {payinCurrency} {operatorFeeSign()}
          {operatorFeeInOriginCurrency}
        </p>
      </SummaryFlexItem>

      {promoDiscountValue > 0 && (
        <SummaryFlexItem>
          <p>
            {promoType === "FIXED_RATE"
              ? "Promo discount rate"
              : "Promo discount"}
          </p>
          <p>
            {promoType === "FIXED_RATE"
              ? `1 ${payinCurrency} = ${promoRate} ${payoutCurrency}`
              : `${payinCurrency} -${promoDiscountValue}`}
          </p>
        </SummaryFlexItem>
      )}

      {rewards.active && (
        <SummaryFlexItem>
          <p>{rewards.type} discount</p>
          <p>
            {payinCurrency} -{rewards.bonus}
          </p>
        </SummaryFlexItem>
      )}

      <Divider style={{ margin: 0 }} />

      <SummaryFlexItem>
        <p>Total to pay</p>
        <p>
          {payinCurrency} {formattedTotalToPay}
        </p>
      </SummaryFlexItem>

      <SummaryFlexItem>
        <p>They receive</p>
        <p>{ToatlPayoutAmount()}</p>
      </SummaryFlexItem>
    </SummaryWrapper>
  );
};
