export const paths = {
  EN_LANDING: "/en/",
  CA_LANDING: "/ca/",
  LANDING: "/",
  SIGN_UP: "/sign-up",
  SIGN_IN: "/sign-in",
  FORGET_PASSWORD: "/forget-password",
  PASSWORD_EMAIL_RESET: "/password-email-reset",
  EMAIL_LINK_SENT: "/email-link-sent",
  PASSWORD_SMS_RESET: "/password-sms-reset",
  SMS_CODE_SENT: "/sms-code-sent",
  CONFIRM_ACCOUNT_SMS: "/sms/confirm-account",
  PASSWORD_RESET_COMPLETE: "/password-reset-complete",
  CONFIRM_ACCOUNT_EMAIL: "/email/confirm-account",
  CONFIRM_ACCOUNT: "/confirm-account/:token",
  PASSWORD_RESET_EMAIL: "/email/password-reset",
  RESET_PASSWORD: "/reset-password/:token",
  VERIFY_PASSWORD_RESET: "/reset-password",
  DASHBOARD: "/dashboard",
  USER_SETTINGS: "/settings",
  PROFILE: "/profile",
  EDIT_PROFILE: "/edit-profile",
  CHANGE_PASSWORD: "/change-password",
  TRANSFER_METHOD: "/transfer-method",
  GET_QUOTE: "/get-quote",
  VERIFICATION: "/verification",
  RECIPIENT: "/recipient",
  RECIPIENT_DETAILS: "/recipient-details",
  REVIEW: "/review",
  PAYMENT_METHOD: "/payment-method",
  CARD_PAYMENT: "/card-payment",
  CREATE_TRANSFER: "/create-transfer",
  TRANSFER_COMPLETE: "/transfer-complete/:transferId?",
  TRANSFER_COMPLETE_AUTH: `/transfer-completed/:transferId?`,
  //--temporary fix
  TRANSFER_COMPLETE_FORAUTH_USER: "/auth-transfer-complete/:transferId?",
  //--
  TRANSFER_STATUS: "/transfer/:status/:transferId?",
  TRUELAYER_PROVIDERS: "/truelayer-providers",
  TRUSTPAYMENT_FOR_MOBILE: "/trustPayment-for-mobile",
  REWARDS: "/rewards",
  REGISTER_COUNTRY: "/country/register",
  NOTIFICATIONS: "/notifications",
  INVITE_BUSINESS_USER: "/business/invite",

  LEGAL: "/company/legal",
  PRIVACY_POLICY: "/company/legal/privacy",
  COOKIE_POLICY: "/company/legal/cookie",
  TERMS: "/company/legal/terms",
  SUPPORT: "/support",
  ABOUT: "/company/about",
  OUR_BLOG: "/company/our-blog",
  TUTORIALS: "/content/about/tutorials",
  HOW_IT_WORKS: "/how-it-works",
  HELP: "/help",
  CAMEROON: "/content/about/cameroon",
  KENYA: "/content/about/kenya",
  UGANDA: "/content/about/uganda",
  TANZANIA: "/content/about/tanzania",
  CONTACT: "/content/contact",
  MOBILE_MONEY_RATE: "/mobile-money-rate",
  AXCESS_MERCHANT: "/axcess-payment",
  EMAIL_REGISTRATION: "/email-registration",

  NOT_FOUND: "*",

  TRANSACTIONS: "/transactions",
  ACCOUNT_STATEMENTS: "/account-statements",
};
