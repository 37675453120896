const endpoints = {
  SIGN_UP: "/users/registration",
  SESSION: "/session",
  INSTANT_SESSION: "/session/instant",
  SIGN_OUT: "/sign-out",
  VALUES: "/values",
  VALUE: "/value",
  USER: "/user/$_1",
  USER_SETTINGS: "/user/settings",
  PASSWORD_REQUEST: "/user/password/request",
  PASSWORD_RESET: "/user/password/reset",
  PASSWORD_CHANGE: "/user/password/change",
  RECIPIENTS: "/user/$_1/recipients",
  RECIPIENT: "/user/$_1/recipient/$_2",
  CREATE_RECIPIENT: "/user/$_1/recipient",
  CREATE_TRANSFER: "/user/$_1/transfer",
  UPDATE_TRANSFER: "/user/transfer/$_1",
  GET_TRANSFER: "/user/$_1/transfer/$_2",
  // GET_TRANSFERS: '/user/$_1/transfers?limit=100&days=7300',
  GET_TRANSFERS: "/user/$_1/transfers",
  QUOTE_SERVICE: "/exchange/$_1/$_2",
  GET_QUOTE: "/quote/$_1",
  GET_SERVICES: "/transfer/services",
  GET_SERVICE: "/transfer/service/$_1",
  INITIATE_PAYMENT: "/user/$_1/payment",
  VERIFICATION: "/user/$_1/verification",
  CONFIRM_ACCOUNT: "/user/email/confirm",
  SUBSCRIBE: "/subscribe",
  NOTIFICATIONS: "/user/$_1/notifications",
  PROMO: "/promo/$_1",
  SAVE_TRULIOO_DOCUMENT_VERIFICATION: "/verification/documents",
  TRUELAYER_INITIATE_PAYMENT: "/truelayer/payment/$_1",
  TRUELAYER_PAYMENT_COMPLETED: "/truelayer/completed",
  GET_AXCESS_CHECKOUT_ID: "/axcess/$_1/transfers",
  GET_AXCESS_PAYMENT_NOTIFICATION: "/axcess/payment/notification",
  TOAST_NOTIF: "/user/toast",
  USER_REFERRALS: "/user/referrals",
  REGISTER_COUNTRY: "/country/register",
  COMPETITOR_RATES: "/competitors/rates/$_1/$_2/$_3",
  ACCOUNT_ACTIVATION: "/user/activation",
  TRANSFER_QUOTE: "/transfer/quote",
  VERIFY_PIVOT_REFERENCE: "/pivot/payment/validate",
  INVITE_BUSINESS_USERS: "/business/invite",
  UTC_DATE_TIME_UTIL: "/time/utc",
  EXCHANGE_RATE_SPREADS: "/rate/spreads",
  INTERAC_PAYMENT: "/payments/interac",
  CREATE_PUSH_NOTIFICATION_TOKEN: "/user/notifications/push",
  HEALTH_CHECK: "/health-check",
};

export default endpoints;
